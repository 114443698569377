.mobile-top {
  display: none;
}

@media all and (min-width: 576px) {

  .container {
    max-width: 100%;
  }

  .chat-container {
    width: 100%;
  }

}

@media all and (max-width: 575px) {

  body {
    padding-top: 50px;
    background: #fff;
  }

  .main-nav {
    background-color: #fff !important;
  }

  #navbarNav {

    position: fixed;
    top: 0;
    padding-left: 10px;
    width: 100%;
    left: 0;
    height: 50px;
    background: $primary !important;

    ul {
      display: none;
    }

    .chat-menu {

      display: block;
      line-height: 50px;
      padding-right: 15px;

      a {
        color: #fff;
      }

      .menu-toggle {
        position: relative;
        top: 2px;
        line-height: 34px;
        font-size: 20px;
        color: #fff;
      }

      .badge {
        top: -1px;
        margin-right: 8px;
      }
    }
  }

  #mobileMenu {

    display: block !important;
    position: fixed;
    top: 50px;
    height: 100%;
    right: -100%;
    width: 70%;
    max-width: 600px;
    background: #fff;
    z-index: 999;
    box-shadow: 0 0 80px 0 rgba(0,0,0,.2);
    transition: right 200ms linear;

    padding: 1em .5em;

    ul {
      list-style-type: none;
      padding: 0;

      li {
        display: block;
        width: 100%;
        border-bottom: 1px solid #eee;

        a {
          color: #666;
        }

      }
    }

    &.open {
      right: 0;
    }

    ul.bottom {
      position: absolute;
      bottom: 40px;
      width: 100%;
      display: block;

      li {
        font-size: 13px;
      }
    }
  }

  .content-card {
    border: 0;
  }

  .mobile-top {
    display: block;
  }

  .chat-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    border-radius: 0;

    .chat-medium {

      .inner {
        padding: .5em;
      }

      h2 {
        font-size: 1.2em;
        line-height: 16px;
      }

      .thumb-holder {
        width: 60px;
      }

    }

    .chat-messages-holder {
      padding: 1.5em .5em;
    }

    .message {

      .thumb {
        width: 48px;
        height: 48px;
      }

      &.message-from {

        .thumb-holder {
          margin-right: .5em;
        }

        .message-content {
          margin-right: 1em;
        }
      }

      &.message-to {

        .thumb-holder {
          margin-left: .5em;
        }

        .message-content {
          margin-left: 1em;
        }
      }
    }

  }

  .desktop-header {
    display: none !important;
  }

  #wrapper {
    .main {
      padding: 0 !important;
    }
  }

  #chatWindowTitle {
    display: flex;
    align-items: center;
  }

  .chat-container {
    width: 100%;
    margin: 0;
  }

  .chat-header {
    position:fixed;
    display: flex;
    top: 0;
    width: 100%;
    height: 50px;

    .chat-menu {

      display: block;
      margin-left: auto;
      margin-right: 5px;

      .badge {
        position: relative;
        top: -3px;
        margin-right: 10px;
      }

      .menu-toggle {
        i {
          line-height: 34px;
          font-size: 20px;
          color: #fff;
        }
      }

    }
  }



}