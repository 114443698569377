#chatWindowTitle {

  .mediumStatus {
    font-size: 10px;
    position: relative;
    top: -2px;
    margin-left: 2px;
  }

}

.chat-menu {
  display: none;
}

.chat-container {

  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 940px;
  margin: 2em auto 5px;
  height: 70vh;
  overflow: hidden;

  .chat-messages-holder {
    padding: 2em;
  }

  .chat-messages {
    overflow-y: scroll;
    overflow-x: hidden;
    flex-grow: 1;
  }

  .bubble {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #ccc;
    display: block;

    &.active {
      background: $success;
    }
  }

  .thumb {
    border-radius: 50%;
    overflow: hidden;
  }

  .chat-medium {

    position: sticky;
    flex-shrink: 0;
    z-index: 5;

    .inner {
      display: flex;
      align-items: center;
      padding: .5em 1em;
    }

    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);

    h2 {
      font-size: 1.4em;
      margin-bottom: 0;
    }

    p.title {
      font-size: .9em;
      line-height: 18px;
      margin-bottom: 0;
      margin-top: .4em;
      color: #999;
    }

    .thumb-holder {

      width: 80px;
      position: relative;
      margin-right: 1em;

      .thumb {
        width: 100%;

        img {
          width: 100%;
        }
      }

      .bubble {
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }
  }

  .message {

    margin-bottom: 15px;
    display: none;

    .thumb-holder {
      position: relative;

      .bubble {
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }

    .thumb {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background: #444;
      display: flex;
      align-items: center;

      img {
        width: 100%;

        &.user-image {
          width: 80%;
          display: block;
          margin: 0 auto !important;
        }

      }
    }

    .message-content {
      padding: .8em;
      border-radius: 10px;
      border: 1px solid #eee;
      margin: 0 0 20px 0;

      font-size: .95em;
      line-height: 18px;

      p:last-child {
        margin-bottom: 0;
      }
    }

    .thumb {
      margin-bottom: 10px;
    }

    .meta {
      font-size: .8em;
      padding-left: 4px;
      margin-bottom: 4px;
    }

    &.message-from {

      .thumb-holder {
        margin-right: 1em;

      }
      .meta {
        margin-right: 1em;
      }

      .message-content {
        margin-right: 4em;
        background: #fff;
        box-shadow: 0 0 15px 0 rgba(0,0,0, .05);
      }
    }

    &.message-to {

      .thumb-holder {
        margin-left: 1em;
      }

      .messageHolder {
        margin-left: auto;
        display: flex;
        flex-direction: column;
      }

      .message-content {
        background: #d4ebc3;
        border: 1px solid #c2ebb0;
        margin-left: 4em;
      }

      .meta {
          margin-left: auto;
      }
    }
  }

  .typing {
    display: none;
  }

  .message-form {
    //position: absolute;
    //bottom: 0;
    //left: 0;
    flex-shrink: 0;
    width: 100%;
    padding: 1em;
    min-height: 60px;
    background: #f9f9f9;
    border-top: 1px solid #e1e1e1;

    .write {
      max-height: 120px;
    }
  }

}

.footer-links {
  text-align: center;
  margin-top: 15px;
  a {
    font-size: 13px;
    margin-left: 4px;
    color: #7792ab;
  }
}

.button-overlay {
  position: relative;
  padding: 1em;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(255,255,255,.7);
  }
}