#tarot {
  width: 100%;
  height: 200px;
  overflow: hidden;
  padding: 0;
  max-width: 780px;
  margin: 0 auto 20px;
  position: relative;

  .overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255,255,255,.5);
    z-index: 5;
  }

  &.done {
    .overlay {
      display: block;
    }
  }
}

.tarot-drawn {
  display: none;
  margin: 0 auto 25px;
}

.card-bank {

  width: 560px;
  height: 200px;
  margin: 30px auto 40px;
  position: relative;

  .gameCard {
    background: #fff url('https://www.voyance7.fr/images/tarot/carte-tarot.jpg') no-repeat;
    background-size: 100%;
    width: 100px;
    height: 160px;
    border: 1px solid #afafaf;
    border-radius: 10px;
    float: left;
    position: absolute;
    -webkit-transition-duration: 120ms;
    transition-duration: 120ms;
    cursor: pointer;

    &:hover, &.picked {
      top: -8px;
      box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
    }

    &.picked {
      top: -12px;
      cursor: default;
    }

  }

}

.tarot-result {

  display: flex;
  margin-bottom: 20px;

  .title {
    display: block;
    text-align: center;
  }

  .gameCard {

    width: 90px;
    overflow: hidden;
    margin-right: 10px;
    flex-shrink: 0;

    img {
      width: 100%;
    }

  }

}

.tarotResponse {

  text-align: center;

  > div {

    display: flex;
    justify-content: center;

    .tarot-card {

      width: 19%;
      max-width: 100px;
      margin-right: 10px;
      text-align: center;

      img {
        width: 100%;
        display: block;
        margin-bottom: 5px;
      }

    }
  }

}

@media(max-width: 575px) {

  .tarot-result {
    text-align: center;
    flex-direction: column;

    .gameCard {
      height: auto;
      margin: 0 auto 10px;
    }

    p {
      display: none;
    }

  }

  .card-bank {

    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;

    .mobile-card {
      position: relative;
      left: 0 !important;
      margin: 0 -20px;

      &.picked {
        top: 0;
      }

    }
  }

}


@media(max-width: 480px) {

  .card-bank {

    .mobile-card {
      margin: 0 -30px;
    }

  }

}