$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

$primary: #335c82;
$secondary: #dd2655;
$dark: #232629;

@import "~bootstrap/scss/bootstrap";

body {
    font-family: "Nunito","Helvetica",Arial,sans-serif;
    background: #0a111b url('https://www.voyance7.fr/wp-content/themes/voyance7/images/body-bg.jpg') center top no-repeat;
    background-attachment: fixed;
}

@import "fontawesome/fontawesome";
@import "fontawesome/light";
@import "fontawesome/regular";
@import "fontawesome/solid";
@import "fontawesome/brands";

@import "components/chat";
@import "components/tarot";
@import "components/color";

@import "responsive";

.container {
    max-width: 100%;
}

.card {

    h1 {
        font-size: 1.8em;
        color: #333;
    }

    &.login, &.reset {
        max-width: 500px;
        margin: 0 auto;
    }
}

.container-inner {
    max-width: 940px;
}

#navbarNav {
    display: flex;
    width: 100%;
}

#mobileMenu {
    display: none;
}

.main-nav {
    background-color: rgba(0, 0, 0, .4);

    .badge {
        position: relative;
        top: 6px;
        margin-right: 6px;
    }
}

.tplPart {
    display: none;
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
}

.payment-status {
    text-align: center;
    padding: 1em;
}

.modal-dialog {
    top: 20%;
}

.cgu {

    h1 {
        margin-bottom: 1em;
    }

    h2 {
        font-size: 1.4em;
        margin-top: 20px;
    }

    p, ul {
        color: #666;
    }
}