
.colors {

  .color {
    display: block;
    margin-right: .5em;
    background: #ccc;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.4);
    cursor: pointer;

    &.bleu {
      background: #4286f4;
      &:hover {
        background: darken(#4286f4, 10%);
      }
    }

    &.vert {
      background: #3edb23;
      &:hover {
        background: darken(#3edb23, 10%);
      }
    }

    &.jaune {
      background: #ffeb19;
      &:hover {
        background: darken(#ffeb19, 10%);
      }
    }

    &.rouge {
      background: #ef1010;
      &:hover {
        background: darken(#ef1010, 10%);
      }
    }

    &.blanc {
      background: #fff;
      &:hover {
        background: darken(#fff, 10%);
      }
    }

    &.noir {
      background: #000;
      &:hover {
        background: #333;
      }
    }
  }

}